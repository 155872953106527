@media screen and (max-width: 1024px) {
    .mitsubishi__header {
        font-size: 24px;
        line-height: 28px;
    }

    .mitsubishi__nomenclature {
        justify-content: end;
        display: flex;
        flex-direction: row;
    }

    .mitsubishi__nomenclature-image {
        max-width: 452px;
        height: 326px;
    }

    .mitsubishi__nomenclature-container {
        max-width: 522px;
        height: 274px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .mitsubishi__nomenclature-header {
        font-size: 24px;
        line-height: 32px;
        margin: 6px auto 16px 22px;
        max-width: 346px;
    }

    .mitsubishi__nomenclature-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: 8px;
    }

    .mitsubishi__nomenclature-list-item {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    }
}