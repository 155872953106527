@media screen and (max-width: 1178px) {
    .modules__header {
        max-width: 900px;
        line-height: 34px;
    }

    .modules__description {
        max-width: 900px;
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 1024px) {
    .modules__header {
        max-width: 764px;
        line-height: 34px;
    }

    .modules__description {
        max-width: 764px;
        font-size: 18px;
        line-height: 24px;
    }
}