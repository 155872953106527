.aboutus__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 1440px;
    width: 100%;
}

.aboutus_left-block {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.about-us__description-block {
    display: flex;
    flex-direction: column;
    margin-left: 140px;
    margin-top: 115px;
}

.aboutus__right {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #009138;
    position: relative;
}

.aboutus__carousel {
    display: flex;
    position: absolute;
    right: 0;
    top: 206px;
    max-width: 822px;
    width: 110%;
}

@media screen and (max-width: 1024px) {
    .about-us__slogan {
        height: 457px;
        background-size: 915px;
        background-repeat: no-repeat;
    }

    .about-us__slogan-header {
        margin-left: 50px;
        padding-top: 163px;
    }

    .about-us__slogan-green-rectangle {
        height: 457px;
    }

    .about-us__description {
        max-width: 1024px;
    }

    .about-us__description-block {
        margin-left: 50px;
        margin-top: 63px;
    }

    .about-us__description-description {
        margin: 0 0 25px 0;
    }

    .about-us__description-target {
        margin-right: 99px;
        font-size: 17px;
    }

    .about-us__right-block-header {
        height: 35px;
    }

    .about-us__right-block-header:nth-child(2) {
        height: 16px;
    }

    .about-us__mitsubishi-image {
        max-width: 454px;
        height: fit-content;
        margin-left: 50px;
    }

    .about-us__mitsubishi-description {
        height: 258px;
        max-width: 373px;
        margin-top: 25px;
    }

    .about-us__mitsubishi-description-header {
        font-size: 24px;
        line-height: 32px;
    }

    .about-us__mitsubishi-description-text {
        font-size: 16px;
        line-height: 20px;
    }

    .about-us__mitsubishi-description-link {
        width: 40px;
        height: 40px;
        background-size: 40px;
    }

    .about-us__mitsubishi-green-rectangle {
        display: none;
    }

    .about-us__contacts-green-block-wrapper {
        max-width: 390px;
    }

    .about-us__contacts-services-header {
        font-size: 32px;
        line-height: 40px;
    }

    .about-us__contacts-services-text {
        font-size: 16px;
        line-height: 24px;
    }


}