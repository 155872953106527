@media screen and (max-width: 1156px) {
    .contacts__map-container {
        max-width: 924px;
    }

    .contacts__map-address {
        max-width: 430px;
        min-height: 264px;
    }

    .contacts__map-address-header {
        font-size: 30px;
        line-height: 40px;
        margin: 28px auto 8px 57px;
    }

    .footer__contacts {
        max-width: 320px;
    }

    .contacts__map_text {
        margin-left: 57px;
    }
}