@font-face {
    font-family: 'Fira Sans';
    src: url(./fonts/FiraSans-Regular.ttf),
    url(./fonts/FiraSans-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Noto Sans';
    src: url(./fonts/NotoSans-Regular.ttf),
    url(./fonts/NotoSans-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./fonts/OpenSans-Regular.ttf),
    url(./fonts/OpenSans-Regular.ttf);
    font-weight: 400;
}
