.tech-info {
    /* max-width: 1440px;
    width: 100%; */
    align-self: center;
    font-family: 'Fira Sans';
    font-style: normal;
}

.tech-info__header {
    max-width: 960px;
    width: 100%;
    margin: 52px auto 43px auto;
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    color: #0084C7;
}

.tech-info__table {
    max-width: 1154px;
    width: 100%;
    margin: 0 auto 0 auto;
    display: flex;
}

.tech-info__specification-header {
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #0084C7;
    text-align: center;
    max-width: 568px;
    width: 100%;
    margin: 65px auto 20px auto;
}

.tech-info__specification-card-wrap {
    max-width: 1156px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 61px;
    column-gap: 20px;
}

.tech-info__specification-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 372px;
    width: 100%;
    height: 700px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    /* margin-right: 20px; */
    margin-top: 71px;
}

/* .tech-info__specification-card:last-of-type{
    margin-right: 0px;
} */

.tech-info__specification-card-icon {
    width: 80px;
    display: flex;
    margin: -39px auto 19px 32px;
}

.tech-info__specification-card-title {
    font-weight: 600;
    max-width: 311px;
    font-size: 20px;
    line-height: 32px;
    color: #0084C7;
    margin: 0 auto 20px 32px;
}

.tech-info__specification-card-list {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0 29px auto 50px;
    padding: 0;
    list-style-image: url(../../images/list-img.svg);
}

.tech-info__specification-card-list-item {
    max-width: 311px;
    width: 100%;
    margin: 0;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

}

.tech-info__specification-card-list-item:last-of-type {
    margin-bottom: 0;
}