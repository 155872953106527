.about-us {
    max-width: 1440px;
    width: 100%;
    position: relative;
    margin: 0 auto;
}

.about-us__slogan {
    background-image: url(../../images/slogan-background.svg);
    background-size: 1298px;
    max-width: 1298px;
    width: 100%;
    height: 649px;
    margin: 0;

}

.about-us__slogan-header {
    margin: 0;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 52px;
    color: #0084C7;
    margin-left: 102px;
    padding-top: 230px;
    max-width: 567px;
}

.about-us__slogan-green-rectangle {
    max-width: 710px;
    height: 649px;
    width: 100%;
    background-color: #009138;
    position: absolute;
    z-index: -2;
    right: 0;
    top: 0;
}

.about-us__description {
    margin: 0;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-self: center;
    overflow: hidden;
}

/* .about-us__description-block {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-left: 140px;
    max-width: 710px;
    width: 100%;
} */

.about-us__description-header {
    /* padding-top: 115px; */
    margin: 0;
    margin-bottom: 42px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 60px;
}

.about-us__description-target {
    margin: 0;
    margin-bottom: 32px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-right: 120px;
}

.about-us__description-description {
    margin: 0 0 71px 0;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-right: 24px;
}

.about-us__right-block {
    display: flex;
    flex-direction: column;
    /* background-color: #009138; */
    height: 712px;
    max-width: 822px;
    width: 100%;
}

.green__block {
    position: absolute;
    z-index: -2;
    right: 0;
    background-color: #009138;
    height: 100%;
    max-width: 710px;
    width: 100%;
}

.about-us__right-block-image {
    width: 822px;
    height: 208px;
    margin-top: 206px;
}

.about-us__right-block-header {
    display: flex;
    padding-top: 116px;
    margin-left: 20px;
    height: 40px;
    width: fit-content;
}

.about-us__right-block-header:nth-child(2) {
    padding-top: 0;
    margin-top: 13px;
    height: 20px;
}

.about-us__right-block-header-description_vertical {
    display: flex;
    margin-top: 265px;
    margin-left: 20px;
    height: 173px;
    width: fit-content;
}

.about-us__mitsubishi {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.about-us__mitsubishi-image {
    max-width: 730px;
    width: 50%;
    display: flex;
}

.about-us__mitsubishi-description {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    max-width: 413px;
    width: 100%;
    height: 406px;
    background-color: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    position: relative;
}

.about-us__mitsubishi-description-header {
    max-width: 242px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin-left: 32px;
}

.about-us__mitsubishi-description-text {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 32px;
}

.about-us__mitsubishi-description-link {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url(../../images/arrow.svg);
    background-size: 60px;
    transition: .5s ease-in-out;
}

.about-us__mitsubishi-description-link:hover {
    background-image: url(../../images/arrow-blue.svg);
    transition: all .5s ease-in-out;
}

.about-us__mitsubishi-green-rectangle {
    position: absolute;
    right: 0;
    background-color: #009138;
    width: 50%;
    height: 157px;
    z-index: -2;
}

.about-us__contacts {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;

}

.about-us__contacts-green-block-wrapper {
    background-color: #009138;
    max-width: 556px;
    width: 100%;
}

.about-us__contacts-green-block {
    margin: 33px 42px 51px auto;
    text-align: right;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.about-us__contacts-green-block-header {
    margin: 0 0 16px auto;
    /* text-decoration: none;
    font-style: normal; */
}

.about-us__contacts-green-block-address {
    max-width: 370px;
    width: 100%;
    margin: 0 0 7px auto;
}

.about-us__contacts-green-block-tel {
    color: white;
}

.about-us__contacts-green-block-fax {
    margin: 0;
}

.about-us__contacts-green-block-email {
    color: white;
}

.about-us__contacts-services {
    margin-left: 56px;
}

.about-us__contacts-services-header {
    margin: 31px auto 0 0;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
}

.about-us__contacts-services-text {
    margin: 0;
    max-width: 666px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.about-us__contacts-services-link {
    display: flex;
    margin-top: 16px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    max-width: fit-content;
    color: #B0001E;
}