.contacts {
    max-width: 1440px;
    width: 100%;
    margin: 164px auto 0 auto;
    display: flex;
    justify-content: center;
    font-family: 'Fira Sans';
    font-style: normal;
}

.contacts__map-container {
    max-width: 1156px;
    width: 100%;
    position: relative;
}

.contacts__map-address {
    position: absolute;
    max-width: 470px;
    min-height: 317px;
    width: 100%;
    left: 50px;
    top: 75px;
    background-color: white;
    border-radius: 8px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    filter: drop-shadow(0px 14.6137px 40.1877px rgba(0, 0, 0, 0.1));
}

.contacts__map-address-header {
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    color: #0084C7;
    margin: 29px auto 24px 64px;
}

.contacts__map_text {
    margin-top: 0;
    margin-left: 64px;
}

.contacts__map_text-modifier {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}