p {
    margin: 0;
}

.footer {
    width: 100%;
    background-color: #DEDFE3;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: black;
    height: 280px;
}

.footer__wrapper {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.footer__container {
    max-width: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 72px;
    /* margin-right: 123px; */
}

.footer__container:nth-last-of-type(2) {
    margin-left: 0;
    /* margin-right: 66px; */
}

.footer__link {
    margin-bottom: 6px;
    color: black;
}

.footer__logo {
    width: 176px;
    height: 62px;
    /* margin-right: 118px; */
    margin-top: 65px;
}

.footer__contacts {
    margin-top: 72px;
    max-width: 370px;
    display: flex;
    flex-direction: column;
}

.footer__contacts-link {
    margin-bottom: 16px;
    color: black;
}

.footer__contacts-tel {
    color: black;
}

.footer__contacts-email {
    color: black;
}

.footer__button-to-top {
    position: absolute;
    right: 50px;
    top: 24px;
    /* margin: 31px 149px auto 25px; */
    background-image: url(../../images/arrow-to-top.svg);
    /* background-size: 26px 26px; */
    background-size: contain;
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}