.mitsubishi {
    font-family: 'Fira Sans';
    font-style: normal;
    margin: 124px auto 0 auto;
    max-width: 1440px;
    width: 100%;
}

/* .mitsubishi__container {
    max-width: 1440px;
    display: flex;
    justify-content: center;
    flex-direction: column;
} */

.mitsubishi__header {
    align-self: center;
    margin: 0 auto 37px auto;
    max-width: fit-content;
    max-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: justify;
    color: #0084C7;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
}

.mitsubishi__graph {
    max-width: 1150px;
    width: 100%;
    /* height: 328px; */
    margin: 0 auto 0 auto;
    display: flex;
    justify-self: center;
    align-self: center;
}

.mitsubishi__nomenclature {
    max-width: 1298px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 40px 0 0 auto;
}

.mitsubishi__nomenclature-image {
    max-width: 568px;
    max-height: 410px;
    /* margin: 40px 0 0 142px; */


}

.mitsubishi__nomenclature-container {
    max-width: 730px;
    width: 100%;
    max-height: 373px;
    margin: 22px 0 54px 0;
    background-color: #0084C7;
    color: white;
}

.mitsubishi__nomenclature-header {
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    margin: 31px auto 28px 28px;
    max-width: 404px;
}

.mitsubishi__nomenclature-list {
    max-width: 547px;
    list-style: none;
    list-style-image: url(../../images/list-style.svg);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

}

.mitsubishi__nomenclature-list-item {
    margin-bottom: 13px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}