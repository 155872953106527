@media screen and (max-width: 1210px) {
    .tech-info__header {
        font-size: 30px;
        line-height: 40px;
    }

    .tech-info__table {
        max-width: 924px;
    }

    .tech-info__specification-card-wrap {
        max-width: 765px;
        justify-content: unset;
        margin: 0 auto 50px auto;
    }
}