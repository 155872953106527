.benefits {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 104px auto 97px auto;
    gap: 23px;
}

.benefits__item {
    max-width: 275px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* margin-right: 20px; */
}

.benefits__item:last-of-type {
    margin-right: 0;
}

.benefits__item-icon {
    display: flex;
    width: 64px;
    height: 52px;
    margin-bottom: 22px;
    align-self: center;
    justify-self: center;
}

.benefits__item-description {
    display: flex;
}