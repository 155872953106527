@media screen and (max-width: 1024px) {
    .benefits {
        gap: 23px;

    }

    .benefits__item {
        font-size: 16px;
        line-height: 20px;
        margin-right: 0;
    }

}