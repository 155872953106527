.popup-wrap {
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    position: relative;
    display: flex;
    visibility: hidden;
    height: 100%;
    inset: 0;
    justify-content: center;
    left: 0;
    margin: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    z-index: 5;
    transition: visibility .5s,opacity .5s linear;
}

.popup-wrap_visible {
    visibility: visible;
    opacity: 1;
    transition: visibility .5s,opacity .5s linear;
}

.popup {
    display: none;
    max-width: 1124px;
    width: 100%;
    height: 741px;
    overflow-y: scroll;
    position: relative;
    font-family: 'Fira Sans';
    font-style: normal;
    background-color: white;
    /* border-radius: 5px; */

}

.popup_visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
    opacity: 1;
}

/* .popup__header {
    position: fixed;
    height: 123px;
    background-color: white;
} */

.popup__close-btn {
    position: absolute;
    top: 45px;
    right: 45px;
    background-image: url(../../images/close-btn.svg);
    background-size: 15px;
    border: none;
    height: 15px;
    width: 15px;
    cursor: pointer;
    background-color: transparent;
}

.popup__title {
    color: #0084C7;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    max-width: 718px;
    margin: 72px auto 48px 53px;
}

.popup__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    max-width: 1020px;
    margin: 0 auto 28px 53px;
}

.popup__text-list {
    list-style-image: url(../../images/list-img.svg);
    display: flex;
    flex-direction: column;
    margin: 0px 0 21px 69px;
    padding: 0;
    align-self: baseline;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.popup__text-ktp {
    margin-bottom: 14px;
}

.popup__tech {
    max-width: 1020px;
    width: 100%;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    background-color: white;
    /* height: 321px; */
    margin-bottom: 77px;
}

.popup__tech-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    margin: 25px auto 32px auto;
}

.popup__tech-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 60px;
    column-gap: 39px;
    row-gap: 44px;

}

.popup__tech-card {
    max-width: 275px;
    height: 192px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup__tech-card-img {
    width: 80px;
    height: 80px;

}

.popup__tech-card-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}

/* Стиль скролбара */
/* width */
.popup::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.popup ::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.popup::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;

}

/* Handle on hover */
.popup::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9;
}