.schema {
    max-width: 1440px;
    width: 100%;
    font-family: 'Fira Sans';
    font-style: normal;
    align-self: center;
}

.schema__header {
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    margin: 49px auto 0 auto;
    max-width: max-content;
}

.schema__image {
    max-width: 1440px;
    width: 100%;
    max-height: 763px;
}

.schema__advantages {
    max-width: 1194px;
    width: 100%;
    /* display: grid;
    margin: 0 auto 50px auto;
    grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
    grid-template-rows: repeat(2, 1fr); */
    display: flex;
    gap: 21px;
    flex-wrap: wrap;
    margin: 0 auto;

}

.schema__advantages-item {
    display: flex;
    flex-direction: row;
    width: 384px;
    height: 115px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.schema__advantages-item-img {
    height: 83px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0 16px 0 10px;
}

.schema__advantages-item-wrap {
    display: flex;
    flex-direction: column;
    max-width: 264px;
    margin-top: 16px;
}

.schema__advantages-item-header {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1B5F8B;
}

.schema__advantages-item-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 4px 0 auto 0;
}