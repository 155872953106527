.not-found {
    width: 100%;
    height: 902px;
    background-color: #0084C7;
    display: flex;
    flex-direction: column;
}

.not-found-image {
    display: flex;
    margin: 80px auto 8px auto;
    max-width: 764px;
    width: 100%;
}

.not-found-title {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    color: #FFFFFF;
    max-width: 637px;
    width: 100%;
    text-align: center;
    margin: 0 auto 24px auto;
}

.not-found-link {
    background-color: #368D43;
    border-radius: 36.7478px;
    color: white;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    max-width: 372px !important;
    width: 100%;
    text-align: center;
    height: 50px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}